import { compose, setDisplayName, wrapDisplayName, withProps } from "recompose";

const withConfig = Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, "withConfig")),
    withProps(() => ({
      config: window.settings
    }))
  )(Component);

export default withConfig;
