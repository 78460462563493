import React from "react";
import "../styles/components/common/pageNotFound.scss";

const PageNotFound = () => 
<div className="page-not-found">
  <div className="inner-container">
    <div className="logo"></div>
    <h1>LinkInBio</h1>
    <p className="error-message">Oops! Page not found.</p>
  </div>
</div>;

export default PageNotFound;
