import React from "react";
import MainPage from "./components/MainPage";
import { Route, Switch } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import PageNotFound from "./components/PageNotFound";

function App() {
  return (
    <div className="">
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/:slug/:category?" exact component={MainPage} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}
export default App;
