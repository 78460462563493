import React from "react";
import { useViewport } from "../../infrastructure/ViewportProvider";

// Escaping class component to use react hook for breakpoints
// TODO: refactor useViewport so it can be used in class components too
function CircleLoader(categories) {
  const { width } = useViewport();
  const mdBreakpoint = 768;

  return (
    <>
    {categories ? (
      width < mdBreakpoint ? (
      <svg width="100" height="100">
          <circle cx="32" cy="32" r="31"/>
      </svg>                       
      ) : (
      <svg width="100" height="100">
          <circle cx="47" cy="47" r="46"/>
      </svg> 
      )     
    ) : (
      width < mdBreakpoint ? (
        <svg width="100" height="100">
          <circle cx="32" cy="32" r="28"/>
        </svg>                       
      ) : (
        <svg width="100" height="100">
          <circle cx="64" cy="64" r="58"/>
        </svg>    
      )
    )
    }
    </>
  );
}
export default CircleLoader;
