import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewportWrapper from "./common/ViewportWrapper.js";
import Categories from "./Categories.js";
import "../styles/components/gridView.scss";

class GridView extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      tooltip: false,
      loading: true,
      isHover: false
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  componentDidMount() {
    this.showTooltip();
  }

  onMouseEnterHandler = selected => {
    this.setState({
      isHover: selected
    });
  }

  onMouseLeaveHandler = selected => {
    this.setState({
      isHover: selected
    });
  }

  imageAspectRatio = ({ target: img }) => {
    let ratio = img.offsetWidth / img.offsetHeight;
    // "show" class for loading transition on images
    img.className += " show";
    img.className += ratio > 1 ? " landscape" : " portrait";
  }

  onLinkClick = async post => {
    const { trackEvent } = this.props;
    const category = "Link Click";
    const action =
      this.props.match.params.slug + ":" + post.id;
    const label = post.link;
    trackEvent(category, action, label);
  }  

  showTooltip() {
    if(!this.state.tooltip) {
      setTimeout(() => {
        this.setState({tooltip: true});
      }, 1500);      
    }
  }

  showCategories() {
    return this.props.profile.showCategories && this.props.profile.categories.length;
  }

  isCategoryActive = category => {    
    if (category.isPermanent && this.props.category === 'all'){
      return true;
    }
    return category.id === this.props.category;
  }

  getGridItems() {
    const { postsByCategory }  = this.props.profile;
    const posts = postsByCategory[this.props.category].posts;

    return posts.map(post => {
    const width = this.props.width;
    const mdBreakpoint = 992;
    const postTitle = post.title && post.title.substring(0, 86);
    const showPostTitles = width > mdBreakpoint && post.title && post.title.length;
    const isActiveClass = this.state.isHover === post.id && width > mdBreakpoint && post.title ? "active" : "";
      return (
        <Col 
          className={`grid-col ${showPostTitles ? 'with-post-title' : ''} ${isActiveClass}`}
          key={post.id} 
          xs={6} 
          sm={6} 
          md={4} 
          lg={3} 
          onMouseEnter={() => this.onMouseEnterHandler(post.id)}
          onMouseLeave={() => this.onMouseLeaveHandler(false)}
        >
          <Link
            to={{
              pathname: post.link
            }}
            target="blank"
            onClick={() => this.onLinkClick(post)}
            className="post-link"
          >
            <div className="image-container">
              <figure>
                <img
                  src={post.thumbnailImageUrl}
                  alt=""
                  onLoad={this.imageAspectRatio}
                  className="flex-image"
                />
              </figure>
              {showPostTitles &&
                <div className="post-title-wrapper">
                  <div className="post-title">{postTitle}{post.title.length > 55 ? '...' : ''}</div>
                </div>
              }              
            </div>
          </Link>
        </Col>
      );
    });
  }

  render() {

    return (
      <>
        <ViewportWrapper>
          <Categories
            showCategories={this.props.profile.showCategories}
            onCategorySelect={this.props.onCategorySelect}
            categories={this.props.profile.categories}
            category={this.props.category}
            width={this.props.width}
          />
        </ViewportWrapper>
        <Container>
          <hr className={`grid-divider ${this.showCategories ? "with-categories" : ""}`} />
        </Container>

        <div className={`grid-tooltip ${this.state.tooltip === true ? 'show': ''}`}>Explore and learn more by clicking on a post</div>

        <div className={`grid ${this.props.profile.showPostTitles ? "with-post-titles" : ""}`}>
          <Container>
            <Row className="grid-row" noGutters={true}>
              {this.getGridItems()}
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default GridView;