module.exports = { 
    dots: false,
    arrows: true,
    infinite: false,
    speed: 700,
    slidesToShow: 7,
    slidesToScroll: 7,
    touchThreshold: 50,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 7,
                arrows: true,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,  
            }
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                slidesToShow: 7,
                slidesToScroll: 7,                    
            }
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                slidesToShow: 6,
                slidesToScroll: 5,                    
            }
        },
        {
            breakpoint: 420,
            settings: {
                arrows: false,
                slidesToShow: 5,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 374,
            settings: {
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        }
    ]
}