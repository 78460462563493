import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewportWrapper from "./common/ViewportWrapper.js";
import Categories from "./Categories";
import "../styles/components/listView.scss";

class ListView extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      tooltip: false,
      loading: true,
      isHover: false
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  componentDidMount() {
    this.showTooltip();
  }

  onMouseEnterHandler = selected => {
    this.setState({
      isHover: selected
    });
  }

  onMouseLeaveHandler = selected => {
    this.setState({
      isHover: selected
    });
  }

  imageAspectRatio = ({ target: img }) => {
    let ratio = img.offsetWidth / img.offsetHeight;
    // "show" class for loading transition on images
    img.className += " show";
    img.className += ratio > 1 ? " landscape" : " portrait";
  }

  onLinkClick = async post => {
    const { trackEvent } = this.props;
    const category = "Link Click";
    const action =
      this.props.match.params.slug + ":" + post.id;
    const label = post.link;
    trackEvent(category, action, label);
  }  

  showTooltip() {
    if(!this.state.tooltip) {
      setTimeout(() => {
        this.setState({tooltip: true});
      }, 1500);      
    }
  }

  showCategories() {
    return this.props.profile.showCategories && this.props.profile.categories.length;
  }

  isCategoryActive = category => {    
    if (category.isPermanent && this.props.category === 'all'){
      return true;
    }
    return category.id === this.props.category;
  }

  postTitle(title) {
    if(this.props.width < 375 && title.length > 62) {
      return title.substring(0, 62) + "...";
    }

    if(this.props.width < 992 && title.length > 72) {
      return title.substring(0, 72) + "...";
    }

    if(this.props.width > 992 && title.length > 100) {
      return title.substring(0, 100) + "...";
    } 
    return title;
  }

  getListItems() {
    const { postsByCategory }  = this.props.profile;
    const posts = postsByCategory[this.props.category].posts;

    return posts.map(post => {
    const width = this.props.width;
    const mdBreakpoint = 992;
    const showPostTitles = width > mdBreakpoint && post.title && post.title.length;
    const isActiveClass = this.state.isHover === post.id && width > mdBreakpoint && post.title ? "active" : "";
 
      return (
        <Row
          className={`ListView__row ${showPostTitles ? 'with-post-title' : ''} ${isActiveClass}`}
          onMouseEnter={() => this.onMouseEnterHandler(post.id)}
          onMouseLeave={() => this.onMouseLeaveHandler(false)}
          noGutters={true}
          key={post.id}
        >
          <Col
            className="ListView__col"
            xs={6}
          >
            <div className="ListView__image">
              <figure>
                <Link
                  to={{pathname: post.link}}
                  target="blank"
                  onClick={() => this.onLinkClick(post)}
                >                  
                  <img
                    src={post.thumbnailImageUrl}
                    alt=""
                    onLoad={this.imageAspectRatio}
                    className="ListView__image__crop"
                  />
                </Link>
              </figure> 
            </div>
          </Col>
          <Col xs={6} className={`ListView__col ${post.title && post.title.length ? "" : "-no-title"}`}>
            <Link
              className="ListView__link"
              to={{pathname: post.link}}
              target="blank"
              onClick={()=> this.onLinkClick(post)}
            >
              <div className="ListView__meta">
                {post.title && post.title.length &&
                  <div className="ListView__meta__title">{this.postTitle(post.title)}</div>
                }
                <div className="ListView__meta__cta">
                  <span className={ post.ctaIcon != null ? `icon-${post.ctaIcon.toLowerCase()}` : null}></span>
                  <span>{post.cta}</span>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      );
    });
  }

  render() {

    return (
      <>
        <ViewportWrapper>
          <Categories
            showCategories={this.props.profile.showCategories}
            onCategorySelect={this.props.onCategorySelect}
            categories={this.props.profile.categories}
            category={this.props.category}
            width={this.props.width}
          />
        </ViewportWrapper>
        <Container>
          <hr className={`grid-divider ${this.showCategories ? "with-categories" : ""}`} />
        </Container>

        <div className={`grid-tooltip ${this.state.tooltip === true ? 'show': ''}`}>Explore and learn more by clicking on a post</div>

        <div className="ListView">
          <Container>
            <div className="ListView__inner">
              {this.getListItems()}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default ListView;
