import React from "react";
import { useViewport } from "../../infrastructure/ViewportProvider";

function ViewportWrapper({ ...props }) {
  const { width } = useViewport();

  return (
    <>
      {React.cloneElement(props.children, {width, ...props})}
    </>
  );
}

export default ViewportWrapper;