import React from "react";
import { Container } from "react-bootstrap";
import { useViewport } from "../../infrastructure/ViewportProvider";
import "../../styles/components/common/header.scss";

function Header(props) {
  const { width } = useViewport();
  const mdBreakpoint = 768;

  return (
    <header className="Header">
      <Container>
        <div className="Header__inner">
            <div className="Header__inner__col-left">
              <div className="Header__profile-image">
                  <img
                    src={props.profileImageUrl}
                    alt={props.urlName + "display photo"}
                  />
                  {/* <CircleLoader categories /> */}
                  {/* TODO: add this to CircleLoader */}
                  {width >= mdBreakpoint ? (
                    <svg viewBox="0 0 128 128" width="128" height="128">
                      <circle cx="64" cy="64" r="58" stroke="url(#brand-gradient)"/>
                      <defs>
                        <linearGradient id="brand-gradient">
                          <stop offset="0%" stopColor="#FFA048"/>
                          <stop offset="70%" stopColor="#CF3692"/>
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg viewBox="0 0 76 76" width="78" height="78">
                      <circle cx="38" cy="38" r="37" stroke="url(#brand-gradient)"/>
                      <defs>
                        <linearGradient id="brand-gradient">
                          <stop offset="0%" stopColor="#FFA048"/>
                          <stop offset="70%" stopColor="#CF3692"/>
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
              </div>
            </div>
            <div className="Header__inner__col-right">
              <div className="Header__profile-intro">
                {props.urlName &&
                  <h1 className="Header__profile-url-name">{props.urlName}</h1>
                }
                <div className="Header__profile-title">{props.title}</div>
                {props.bio &&
                  <div className="Header__profile-bio">{props.bio}</div>
                }
              </div>
            </div>
        </div>
      </Container>
    </header>
  );
}
export default Header;
