import React, { Component } from "react";
import { compose } from "recompose";
import withApi from "../infrastructure/api/withApi";
import ViewportWrapper from "./common/ViewportWrapper.js";
import Loader from "./common/Loader";
import Header from "./common/Header";
import GridView from "./GridView";
import ListView from "./ListView";
import PageNotFound from "./PageNotFound";
import withGoogleAnalytics from "../infrastructure/tracking/withGoogleAnalytics";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      profile: {},
      loadingNext: false,
    };
    this.getProfileData = this.getProfileData.bind(this);
  }

  componentDidMount() {
    const urlName = this.props.match.params.slug;
    this.getProfileData(urlName);
    const page = "LinkInBio - Account Page: " + urlName;
    this.props.trackPageView(page, "/" + urlName);
  }

  async getProfileData(urlName) {
    const { callApi, history } = this.props;
    const urlCategory = this.props.match.params.category;
    try {
      const res = await callApi(`api/account/${urlName}`);
      const categoryState = {};
      categoryState['all'] = {
        pageIndex: 1,
        posts: res.data.posts,
        hasMore: res.data.hasMore
      }
      this.setState({
        profile: { ...res.data, postsByCategory: { ...categoryState } },
        loading: false,
        urlName,
        category: 'all'
      });
      let categoryToLookUp = '';
      if (urlCategory) {
        categoryToLookUp = this.state.profile.categories.find(category => category.urlName === urlCategory);

        if (!categoryToLookUp) {
          history.push(`/${urlName}`);
        }
        else if (urlCategory !== 'all') {
          this.changeCategory(categoryToLookUp.id);
        }
        else {
          history.push(`/${urlName}`);
        }
      }
    } catch (err) {
      this.setState({
        loading: false
      });
    }
  }

  changeCategory = async (category) => {
    const { callApi, history } = this.props;
    const { urlName } = this.state;
    const categoryClicked = this.state.profile.categories.find(categoryClicked => categoryClicked.id === category);
    if (!categoryClicked) {
      history.push(`/${urlName}`)
    } else {
      history.push(`/${urlName}/${categoryClicked.urlName}`)
    }
    if (this.state.profile.postsByCategory[category]) {
      this.setState({
        loading: false,
        category
      })
    } else {

      try {
        const res = await callApi(`api/account/${urlName}?category=${category}`);
        const categoryState = {};
        categoryState[category] = {
          pageIndex: 1,
          posts: res.data.posts,
          hasMore: res.data.hasMore
        }
        this.setState({
          profile: { ...this.state.profile, postsByCategory: { ...this.state.profile.postsByCategory, ...categoryState } },
          loading: false,
          category: category,
        });
      } catch (err) {
        this.setState({
          loading: false
        });
      }
    }
  }

  getNextPage = async () => {
    this.setState({ loadingNext: true });
    const { callApi } = this.props;
    const { profile: { postsByCategory }, urlName, category } = this.state;
    const currentCategory = postsByCategory[category];
    const { pageIndex, posts } = currentCategory;

    const response = await callApi(
      `api/account/${urlName}?pageIndex=${pageIndex + 1}&category=${category === 'all' ? '' : category}`
    );
    const { data: { posts: newPosts, hasMore } } = response;
    const updatedCategory = {};
    updatedCategory[category] = { ...currentCategory, posts: [...posts, ...newPosts], hasMore: hasMore, pageIndex: pageIndex + 1 };
    this.setState(prevState => ({
      profile: {
        ...prevState.profile,
        postsByCategory: { ...postsByCategory, ...updatedCategory }
      },
      loadingNext: false,
    }));
  };

  render() {
    const layout = this.state.profile.layout;
    return (
      <main>
        {this.state.loading ? (
          <Loader />
        ) : this.state.profile.urlName ? (
          <>
            <Header
              profileImageUrl={this.state.profile.profileImageUrl}
              urlName={this.state.profile.urlName}
              title={this.state.profile.title}
              bio={this.state.profile.bio}
            />
            {layout === 'grid' ? (
              <ViewportWrapper>
                <GridView
                  {...this.props}
                  profile={this.state.profile}
                  category={this.state.category}
                  onCategorySelect={this.changeCategory}
                />
              </ViewportWrapper>
            ) : (
                <ViewportWrapper>
                  <ListView
                    {...this.props}
                    profile={this.state.profile}
                    category={this.state.category}
                    onCategorySelect={this.changeCategory}
                  />
                </ViewportWrapper>
              )
            }
            {this.state.profile.postsByCategory[this.state.category].hasMore && (
              <div className="load-posts-wrapper">
                <button
                  className={this.state.loadingNext ? "btn-transparent loading" : "btn btn-load-posts"}
                  onClick={this.getNextPage}
                >
                  {this.state.loadingNext ? (
                    <Loader gridView />
                  ) : (
                      "Load more"
                    )}
                </button>
              </div>
            )}
          </>
        ) : (
              <PageNotFound />
            )}
      </main>
    );
  }
}

const enhance = compose(
  withApi,
  withGoogleAnalytics
);

export default enhance(MainPage);
