import { compose, setDisplayName, wrapDisplayName, withProps } from "recompose";
import Axios from "axios";
import withConfig from "../config/withConfig";

const withApi = Component =>
  compose(
    withConfig,
    setDisplayName(wrapDisplayName(Component, "withApi")),
    withProps(({ config }) => ({
      callApi: (url, method) => {
        const request = {
          method,
          url: `${config.apiUrl}${url}`,
          headers: {
            Pragma: "no-cache"
          }
        };
        return Axios(request);
      }
    }))
  )(Component);

export default withApi;
