import React from "react";
import "../../styles/components/common/loader.scss";

function Loader({ gridView }) {
  return (
    <div className={gridView ? "grid-view-loader-wrapper" : "loader-wrapper"}>
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
