import React from "react";
import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { render } from "react-dom";
import App from "./App";
import { ViewportProvider } from "./infrastructure/ViewportProvider.js";
import { BrowserRouter as Router } from "react-router-dom";

render(
  <Router>
    <ViewportProvider>
      <App />
    </ViewportProvider>
  </Router>,
  document.getElementById("root")
);
