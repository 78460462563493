import React, { useEffect } from "react";
import withGoogleAnalytics from "../infrastructure/tracking/withGoogleAnalytics";
import "../styles/landingPage.scss";

const LandingPage = ({ trackPageView }) => {

  // eslint-disable-next-line
  useEffect(() => trackPageView("LinkInBio - Landing Page", "/"), []);

  return (
    <div className="landing-page">
      <div>
        <div className="logo"></div>
        <h1>LinkInBio</h1>
      </div>
    </div>
  );
};

export default withGoogleAnalytics(LandingPage);
