import { compose, setDisplayName, wrapDisplayName, withProps } from "recompose";
import withConfig from "../config/withConfig";

const withGoogleAnalytics = Component =>
  compose(
    withConfig,
    setDisplayName(wrapDisplayName(Component, "withGoogleAnalytics")),
    withProps(({ config }) => ({
      trackEvent: (category, action, label) => {
        const gaEnabled = config.enableGoogleAnalytics;
        if (window.gtag && gaEnabled) {
          const properties = {
            event_category: category,
            event_label: label
          };
          window.gtag("event", action, properties);
        }
      },
      trackPageView: (pageTitle, pagePath) => {
        const gaEnabled = config.enableGoogleAnalytics;
        if (window.gtag && gaEnabled) {
          const properties = {
            page_title: pageTitle,
            page_path: pagePath
          };
          window.gtag(
            "config",
            config.googleAnalyticsWebPropertyId,
            properties
          );
          const propertiesGa4 = {
            page_title: pageTitle,
            page_location: `${config.appUrl.replace(/\/$/, '')}${pagePath}`,
            send_to: config.googleAnalyticsWebPropertyIdGa4,
          };
          window.gtag('event', 'page_view', propertiesGa4);
        }
      }
    }))
  )(Component);

export default withGoogleAnalytics;
