import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SliderSettings from "./CategoriesSliderSettings.js"
import CircleLoader from "./common/CircleLoader.js";
import "../styles/components/listView.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/components/categories.scss";

class Categories extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      tooltip: false,
      loading: true,
      profile: {
        categories: []
      },
      isHover: false
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  onMouseEnterHandler = selected => {
    this.setState({
      isHover: selected
    });
  }

  onMouseLeaveHandler = selected => {
    this.setState({
      isHover: selected
    });
  }

  onLinkClick = async post => {
    const { trackEvent } = this.props;
    const category = "Link Click";
    const action =
      this.props.match.params.slug + ":" + post.id;
    const label = post.link;
    trackEvent(category, action, label);
  }

  selectCategory(e, category) {
    e.stopPropagation();
    this.props.onCategorySelect(category.isPermanent ? 'all' : category.id);    
  }

  isCategoryActive = category => {    
    if (category.isPermanent && this.props.category === 'all'){
      return true;
    }
    return category.id === this.props.category;
  }

  render() {
    const ArrowSettings = {
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }
    const mdBreakpoint = 768;

    return (
      <>
        {this.props.showCategories &&
          <div className="Categories">
            <Container>
              <div className="Categories__slider-container">
                  <div className="slider-wrap">
                  <Slider {...SliderSettings} {...ArrowSettings}>
                      {
                        this.props.categories.map((category, index)=> (
                        <div className={`Categories__item ${category.title.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '-').toLowerCase()} ${this.isCategoryActive(category) ? 'active' : ''}`} key={index}>
                            <Link 
                              to={"#"}
                              onClick={e => this.selectCategory(e, category)}
                              className="Categories__item__link">
                                <div className="Categories__item__image">
                                  <img src={category.imageUrl} alt={category.title} />
                                  <CircleLoader categories />
                                </div>
                                <div className="Categories__item__title">{category.title}</div>
                            </Link>
                        </div>
                        ))
                      }
                      {this.props.width < mdBreakpoint &&
                        <div className="Categories__slide-offset"></div>
                      }
                  </Slider>
                  </div>
              </div>
            </Container>
          </div>
        }
      </>
    );
  }
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle transform="rotate(180 12 12)" cx="12" cy="12" r="12" fill="#474747" fillOpacity=".6"/>
        <path d="m10 17 5-5-5-5" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
      </svg>
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#474747" fillOpacity=".6"/>
        <path d="m14 7-5 5 5 5" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
      </svg>
    </div>
  );
}

export default Categories;
